import classes from "./UploadCard.module.css";
import UploadXlsx from "../icons/UploadXlsx";
import { Typography } from "..";
import { useRef } from "react";

const UploadCard = ((props) => {

  const {
    linkText,
    fileUploaded,
    overMaxInvitesText,
    uploadedFileName,
    uploadText
  } = props

  const uploadRef = useRef(null)

  const openFileSelector = () => {
    uploadRef.current.click()
  }

  return (
    <div className={`${classes.card}
                     ps-5 pe-8 py-10
                     border border-dashed border-app-line
                     rounded-md
                     mb-4
                     ml-80
                     flex`}>
      <div className='flex-col me-8'>
        <UploadXlsx/>
      </div>
      <div className='text-center flex-col'>
        <Typography size="h6" css="block mb-4">
          <div className='text-xs font-tk-usual'>
            {uploadedFileName}
          </div>
          <span dangerouslySetInnerHTML={{__html:  uploadText}}/>
          {overMaxInvitesText != "" &&
            <span dangerouslySetInnerHTML={{__html: overMaxInvitesText}}/>
          }
          <input
            type="file"
            name="file"
            id="upload"
            ref={uploadRef}
            onChange={(e) => fileUploaded(e.target.files[0])}
            accept=".xlsx"
            className="hidden"/>
          <a
            href="#"
            className={"text-app-defaultColor underline"}
            onClick={openFileSelector}>
              {linkText}
          </a>
        </Typography>
      </div>
    </div>
  )
})

export default UploadCard;