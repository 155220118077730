import classes from "./RadioCard.module.css";
import { Typography } from "../typography";
import { Tooltip } from "@mui/material";

const RadioCard = ((props) => {
  const { onClick, option, selected } = props

  const disabledButton = () => {
    return (
      <Tooltip title={option.tooltips?.disabled} placement="right" arrow>
        <div className={`relative ${classes.card} ${classes.disabledPointer} mb-4 p-4 border-1 border-app-grayTwo`}>
          <div className={`flex-col ${classes.dot} border-2 border-app-grayTwo`}/>
          <div className={'flex-col'}>
            <div className='mb-4'>
              {option.icon && option.icon}
            </div>
            <h4 className="block mb-4 text-xl font-semibold">
              {option.title}
            </h4>
            <h5 className="block text-base mb-4">
              {option.description}
            </h5>
          </div>
          { option.soon && (
            <div className="absolute top-0 right-0 z-50 bg-app-defaultColor text-white px-2 py-1 text-sm font-bold rounded-tr-lg">
              {option.soon}
            </div>
          )}
        </div>
      </Tooltip>
    )
  }

  const enabledButton = () => {
    return (
      <div className={`${classes.card} last:mb-0 mb-4 p-4 border-1 ${selected ? 'border-app-orange bg-app-grayOne' : ""}`}
          onClick={onClick}>
        <div className={`flex-col ${classes.dot} ${selected ? `border-app-orange ${classes.border6}` : 'border-2 border-app-darkbg'}`}/>
        <div className={'flex-col'}>
          <div className='mb-4'>
            {option.icon && option.icon}
          </div>
          <h4 className="block mb-4 text-xl font-semibold">
            {option.title}
          </h4>
          <h5 className="block text-base mb-4">
            {option.description}
          </h5>
        </div>
      </div>
    )
  }

  return (
    <>
      { option.disabled &&
        disabledButton()
      }
      { !option.disabled &&
        enabledButton()
      }
    </>
  )
})
  
export default RadioCard;
