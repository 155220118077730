import classes from './Wizard.module.css';
import { useState, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import { ProgressBar, Typography, Button } from "../../components";
import { nextAPI } from "../../api/axiosAPI"
import { useRouter } from 'next/router';
import WestIcon from '@mui/icons-material/West';
import Spinner from '@components/Spinner';
import LockOpenIcon from '@mui/icons-material/LockOpen';


const Wizard = (props) => {
  const {
    currentStep,
    description,
    descriptionSupplement,
    showProgressBar,
    steps,
    title,
    backUrl,
    nextCallback,
    nextUrl,
    supplementaryButtonText,
    supplementaryButtonAction
  } = props
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const onNextClick = () => {
    if (nextCallback) {
      nextCallback()
    } else if (nextUrl) {
      router.push(nextUrl, null, { shallow: true })
    }
  }


  useEffect(() => {
    const handleWizardAccessBlock = async () => {
      setLoading(true);
      const category = sessionStorage.getItem("ticketsWizardCategory")
      const customer = JSON.parse(localStorage.getItem("customer"))

      const body = {
          customerId: customer.id,
          size: 1,
          sort_order: "desc",
          sort_by: "created_at",
          locale: "it",
      };

      const { data } = await nextAPI.post("/upload", body);
      if (data.length > 0) {
          const lastUpload = data[0];
        if (lastUpload.status.toLowerCase() === "processing") {
            router.push({
                pathname: category === "g" ? "/" : "/op_management",
                query: {
                    mode: "wizardBlock"
                }
            });
          }
      }
      setLoading(false);
    }
    handleWizardAccessBlock()
  }, [])

  return (
    <>
      {loading && <Spinner />}
      <div className={classes.wizardContainer}>
        <div className={classes.header}>
          <a 
            className="text-app-defaultColor underline flex items-center cursor-pointer hover:opacity-80 w-fit"
            onClick={() => {
              setLoading(true);
              router.push("/", null, { shallow: true }) 
            }}
          >
            <WestIcon />
            <span
              className="ml-2"
            >{t(`wizard:home`)}</span>
          </a>
        </div>
        <div className={`${classes.bodyContainer} flex flex-col`}>
          <h2 className="text-3xl block mb-3 font-semibold">
            {title}
          </h2>
          { showProgressBar &&
            <ProgressBar steps={steps} currentStep={currentStep}/>
          }
          <div className='mb-2'>
            <h2 className="text-2xl font-semibold inline">
              {description}
            </h2>
            {descriptionSupplement && (
              <h2 className='underline text-2xl font-semibold inline'>
                {descriptionSupplement}
              </h2>
            )}
          </div>


          { props.children }
        </div>
        <div className={`${classes.footer} flex flex-row`}>
          <Button
            variant="secondary"
            css="mx-0 h-[50px]"
            onClick={() => { router.push(backUrl, null, { shallow: true }) }}
          >
            {t(`wizard:back`)}
          </Button>

          {supplementaryButtonText && (
            <Button
              onClick={supplementaryButtonAction}
              variant={supplementaryButtonAction ? "secondary" : "disabled-btn"}
              disabled={!supplementaryButtonAction}
              css={`ml-auto mr-2 h-[50px] ${supplementaryButtonAction ? "hover:bg-app-defaultColor hover:text-white transition-all" : "cursor-not-allowed"}`}
              endIcon={<LockOpenIcon />}
            >
              {supplementaryButtonText}
            </Button>
          )}
          

          <Button
            // TODO _CR_: assegnate dei data-testid statici a tutti gli elementi con cui interagisce l'utente, così da poterci scrivere dei test in futuro
            data-testid="wizard-next-btn"
            css="mx-0 h-[50px]"
            disabled={!nextUrl && !nextCallback} // TODO: tickets wizard - remove !nextCallback
            variant={nextUrl || nextCallback ? "primary" : "disabled-btn"}
            onClick={onNextClick}
          >
            {currentStep && currentStep < steps.length ? t(`wizard:next`) : t(`wizard:send`)}
          </Button>
        </div>
      </div>
    </>
  )
}

Wizard.defaultProps = {
  showProgressBar: true
}

export default Wizard;