import { useState, useEffect } from "react";
import { Radio } from "../radio";
import { VisaIcon, AliPayIcon, AEIcon, MasterCardIcon,
      SepaIcon, SofortIcon, WeChatPayIcon, } from "../icons";
import { formatAmountForStripe } from "../../helpers/stripeHelper";
import { CardElement, IbanElement,
      useStripe, useElements, } from "@stripe/react-stripe-js";
import { Button } from "../button";
import { nextAPI } from "../../api/axiosAPI";
import { useRouter } from "next/router";
import { useToasts } from "react-toast-notifications";
import Modal from "../modal/modal";
import Constants from "../../utilities/Constants";

import useTranslation from "next-translate/useTranslation";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#343A40",
      width: "100%",
      height: "150px",
      "::placeholder": {
        color: "#8D8D8D",
      },
      ":focus": {
        color: "green",
      },
    },
    invalid: {
      color: "#DC3545",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#343A40",
      width: "100%",
      height: "150px",
      "::placeholder": {
        color: "#8D8D8D",
      },
      ":focus": {
        color: "green",
      },
    },
    invalid: {
      color: "#DC3545",
    },
  },
};

const PaymentMethods = ({ prepareOrder, customer,
      pageTranslationCode, category = "g", setIsLoading, invoiceAvailable}) => {

  const { t, lang } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const router = useRouter();
  const { addToast } = useToasts();

  const tCode = `${pageTranslationCode}:paymentMethods`;

  const [radioOption, setRadioOption] = useState({
    carta: false,
    alipay: false,
    sofort: false,
    wechat: false,
    bonifico: false,
    bonifico_pay: false,
  });

  const [pageLoading, setLoading] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(null);
  const [newErrorMessage, setNewErrorMessage] = useState("")
  const [errorModalOpen, setErrorModalOpen] = useState(false)

  useEffect(() => {
    if (radioOption.carta || radioOption.bonifico_pay || radioOption.alipay) {
      setIsOptionSelected(true);
    } else {
      setIsOptionSelected(false);
    }
  }, [radioOption]);
  
  const prepareStripeCreditCardPayment = async () => {
    let amount = prepareOrder.amount;
    let order_number = prepareOrder.number;
    let _customer_id = customer.payment_customer_id ?? undefined;

    let payload = {
      amount: formatAmountForStripe(amount),
      currency: "eur",
      order_number,
      customer_id: _customer_id,
      customer_type: customer.customer_type,
      email: customer.email,
      lang,
      invoice_available: invoiceAvailable,
      items: prepareOrder.items,
    };

    const stripeCreditCardPayment = await nextAPI.post("/orders/stripe", payload);

    const stripePaymentsInfoToSend = {
      stripe_customer_id: _customer_id,
      intent_id: stripeCreditCardPayment.data.id,
      order_id: prepareOrder.id,
      method: stripeCreditCardPayment.data.payment_method_types[0]
    }

    await sendPaymentsInfo(stripePaymentsInfoToSend)

    return stripeCreditCardPayment.data
  };

  const prepareStripeAlipayPayment = async () => {
    let amount = prepareOrder.amount;
    let order_number = prepareOrder.number;
    let _customer_id = customer.payment_customer_id ?? undefined;

    let payload = {
      amount: formatAmountForStripe(amount),
      currency: "eur",
      order_number,
      customer_id: _customer_id,
      customer_type: customer.customer_type,
      lang,
      invoice_available: invoiceAvailable,
      items: prepareOrder.items,
    };

    return await nextAPI.post("/orders/alipay", payload);
  }

  const prepareStripeBankTransferSEPAPayment = async () => {
    
    let _customerId = customer.payment_customer_id ?? undefined;
    let order_number = prepareOrder.number;

    let payload = {
      order_number,
      customer_id: _customerId,
      currency: "eur",
      email: customer.email,
      name: customer.company_name,
      invoice_available: invoiceAvailable,
      items: prepareOrder.items,
    };

    if(!payload.name
      || payload.name === '') {
        payload.name = customer.firstname
    }

    const stripeBankTransferSEPAPayment = await nextAPI.post("/orders/sepa-pay", payload);

    let stripePaymentsInfoToSend = {
      stripe_customer_id: _customerId,
      source_id: stripeBankTransferSEPAPayment.data.id,
      order_id: prepareOrder.id,
      method: stripeBankTransferSEPAPayment.data.type
    }

    if(stripeBankTransferSEPAPayment.data.type === Constants.SEPA_CREDIT_TRANSFER_PAYMENT_METHOD) {
      stripePaymentsInfoToSend.stripe_sepa_bank_name = stripeBankTransferSEPAPayment.data.sepa_credit_transfer.bank_name
      stripePaymentsInfoToSend.stripe_sepa_bank_iban = stripeBankTransferSEPAPayment.data.sepa_credit_transfer.iban
      stripePaymentsInfoToSend.stripe_sepa_bank_bic = stripeBankTransferSEPAPayment.data.sepa_credit_transfer.bic
    }

    await sendPaymentsInfo(stripePaymentsInfoToSend)

    var notificationEmailSepaPayload = {
      number: order_number,
    };

    await sendNotificationEmailSepa(notificationEmailSepaPayload)

    return stripeBankTransferSEPAPayment.data
  };

  const sendPaymentsInfo = async (paymentsInfoToSend) => {
    if(paymentsInfoToSend) {
      const paymentsInfoResult = await nextAPI.post("/orders/payments_info", paymentsInfoToSend);
    }
  }

  const sendNotificationEmailSepa = async (notificationEmailSepaPayload) => {
    const paymentsInfoResult = await nextAPI.post("/notification/email/sepa", notificationEmailSepaPayload);
  }

  const checkCustomerInSessionPaymentId = async () => {
    if(!customer.payment_customer_id
      || typeof customer.payment_customer_id === 'undefined'
      || customer.payment_customer_id === '') {
      addToast(t(`${pageTranslationCode}:paymentMethods.customerPaymentsIDIssue`), {
        appearance: "error"
      });

      return false;
    }

    return true
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    setIsLoading(true)

    try {
      e.preventDefault();

      const checkCustomerInSessionPaymentIdResult = await checkCustomerInSessionPaymentId()

      if(
        !stripe ||
        !elements ||
        !checkCustomerInSessionPaymentIdResult
      ) {
        setLoading(false)
        setIsLoading(false)
        return
      }
      
      let result;

      let stripePaymentData = {}
      let bankName = false
      let iban = false
      let bic = false
      let total = false
      if (radioOption.carta === true) { 
        stripePaymentData = await prepareStripeCreditCardPayment();
        result = await payWithCard(stripePaymentData);
      } else if (radioOption.bonifico_pay === true) {
        stripePaymentData = await prepareStripeBankTransferSEPAPayment();
        result = await payWithTransferSEPA(stripePaymentData);
        bankName = stripePaymentData.sepa_credit_transfer.bank_name
        iban = stripePaymentData.sepa_credit_transfer.iban
        bic = stripePaymentData.sepa_credit_transfer.bic
        total = Number(stripePaymentData.metadata.amount)
      } else if (radioOption.alipay === true) {
        stripePaymentData = await prepareStripeAlipayPayment()
        const { error } = await stripe.confirmAlipayPayment(stripePaymentData.data.CL, {
          return_url: `${window.location.href}?orderNumber=${prepareOrder.number}&category=${category}`
        })
      }
  
      let queryString = {
        category,
        state: radioOption.carta ? "success" : "pending",
      }

      if(bankName) {
        queryString.bankName = bankName
      }
      if(iban) {
        queryString.iban = iban
      }
      if(bic) {
        queryString.bic = bic
      }
      if(total) {
        queryString.total = total
      }

      if (result) {
        router.push({
          pathname: "/payment",
          query: queryString,
        });
      }
    }
    catch (error) {
      try {
        const changeStatusPayload = {
          number: prepareOrder.number,
          status: "invoice_error",
        };

        await nextAPI.post(
          "/orders/updatestatus",
          changeStatusPayload
        );
      }
      catch (error) {}
      setNewErrorMessage(error);
      setErrorModalOpen(true);
    }

    setLoading(false)
    setIsLoading(false)
  };

  const payWithCard = async (stripePaymentData) => {
    const card = elements.getElement(CardElement);
    
    const result = await stripe.confirmCardPayment(stripePaymentData.client_secret, {
      payment_method: {
        card,
      }
    });
    if (result.error) {
      const localizedErrorMessage = result.error.code ? t(`${pageTranslationCode}:paymentMethods.${result.error.code}`) : result.error.message;
      setNewErrorMessage(localizedErrorMessage);
      setErrorModalOpen(true);
      throw localizedErrorMessage;
    }
    else {
      return result;
    }
  };

  const payWithTransferSEPA = async (stripePaymentData) => {
    if (stripePaymentData) {
      return {
        paymentIntent: {
          id: stripePaymentData.id,
          payment_method_types: "sepa_credit_transfer",
          status: stripePaymentData.status,
          source: stripePaymentData.sepa_credit_transfer,
        },
        sepa_credit_transfer: {
          ...stripePaymentData.sepa_credit_transfer,
        },
      };
    }
  };

  //Radio Selections Handler
  const updateRadioOption = (key) => {
    setRadioOption({
      carta: false,
      alipay: false,
      sofort: false,
      wechat: false,
      bonifico: false,
      bonifico_pay: false,
    });

    setRadioOption((prevState) => {
      return {
        ...prevState,
        [key]: true,
      };
    });
  };

  const closeModal = () => {
    setErrorModalOpen(false)
    setLoading(false)
  }

  const SEPA_ENABLED = process.env.SEPA_ENABLED === "true" ? true : false;
  const ALIPAY_ENABLED = process.env.CLIENT_NAME === "SDM" ? true : false;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex mt-11 mb-6 justify-center">
          <span className="text-3xl leading-9 text-center font-bold not-italic font-tk-freight-sans-pro mt-4">
            {t(`${tCode}.title`)}
          </span>
        </div>
        <div className="mx-auto my-0" style={{ maxWidth: 540 }}>
          <div className="flex flex-col justify-between items-center border-solid border-t border-app-disabledlight py-2">
            <div className="flex justify-between w-full">
              <Radio
                checked={radioOption.carta}
                containerClass="flex flex-row items-center"
                labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl mr-5"
                label={t(`${tCode}.credit`)}
                onChange={() => updateRadioOption("carta")}
              />
              <div className="flex flex-row space-x-2">
                <VisaIcon />
                <AEIcon />
                <MasterCardIcon />
              </div>
            </div>
            {radioOption.carta && (
              <>
                <CardElement
                  className="w-full flex flex-col p-5 my-5 border border-app-line rounded-md"
                  options={CARD_ELEMENT_OPTIONS}
                  onReady={(element) => element.focus()}
                />
              </>
            )}
          </div>
          {/**
           * alipay
           */}
           {
            ALIPAY_ENABLED && (
              <div className="flex flex-col justify-between items-center border-solid border-t border-app-disabledlight py-2">
                <div className="flex justify-between w-full">
                  <Radio
                    checked={radioOption.alipay}
                    containerClass="flex flex-row items-center"
                    labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl"
                    label="AliPay"
                    onChange={() => updateRadioOption("alipay")}
                  />
                  <div>
                    <AliPayIcon />
                  </div>
                </div>
              </div>
            )
           }
          {/**
           * sofort pay
           */}
          {/* <div className="flex flex-col justify-between items-center border-solid border-t border-app-disabledlight py-2">
            <div className="flex justify-between w-full">
              <Radio
                checked={radioOption.sofort}
                containerClass="flex flex-row items-center"
                labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl"
                label="Sofort"
                onChange={() => updateRadioOption("sofort")}
              />
              <div>
                <SofortIcon />
              </div>
            </div>
            {radioOption.sofort && <span>I am Sofort</span>}
          </div> */}
          {/**
           * weChat pay
           */}
          {/* <div className="flex flex-col justify-between items-center border-solid border-t border-app-disabledlight py-2">
            <div className="flex justify-between w-full">
              <Radio
                checked={radioOption.wechat}
                containerClass="flex flex-row items-center"
                labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl"
                label="WeChat Pay"
                onChange={() => updateRadioOption("wechat")}
              />
              <div>
                <WeChatPayIcon />
              </div>
            </div>
            {radioOption.wechat && <span>I am We Chat Pay</span>}
          </div> */}
          {/**
           * addebito bancario
           */}
          {/* <div className="flex flex-col justify-between items-center border-solid border-t border-app-disabledlight py-2">
            <div className="flex justify-between w-full">
              <Radio
                checked={radioOption.bonifico}
                containerClass="flex flex-row items-center"
                labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl"
                label="Addebito Bancario"
                onChange={() => updateRadioOption("bonifico")}
              />
              <div>
                <SepaIcon />
              </div>
            </div>
            {radioOption.bonifico && (
              <>
                <div className="form-row w-full my-5">
                  <label>
                    {"IBAN"}
                    <IbanElement
                      className="w-full flex flex-col p-5 my-5 border border-app-line rounded-md"
                      options={IBAN_ELEMENT_OPTIONS}
                      options={{
                        supportedCountries: ["SEPA"],
                        placeholderCountry: "IT",
                      }}
                    />
                  </label>
                </div>

                <div className="mandate-acceptance my-5">
                  {`
                  Fornendo le tue informazioni di pagamento e confermando questo pagamento,
                  autorizzi (A) I SALONI NAUTICI SRL e Stripe, 
                  il nostro fornitore di servizi di pagamento, 
                  a inviare istruzioni alla tua banca per addebitare il tuo conto e (B) la tua banca ad addebitare il tuo conto in conformità con quelle istruzioni. 
                  Come parte dei tuoi diritti, hai diritto a un rimborso dalla tua banca secondo i termini e le condizioni del tuo accordo con la tua banca. 
                  Il rimborso deve essere richiesto entro 8 settimane a partire dalla data in cui è stato addebitato il tuo conto.
                  I tuoi diritti sono spiegati in una dichiarazione che puoi ottenere dalla tua banca.
                  Accetti di ricevere notifiche per addebiti futuri fino a 2 giorni prima che si verifichino..
                  `}
                </div>
              </>
            )}
          </div> */}
          {/**
           * bonifico bancario
           */}
          {/*!SDM && */SEPA_ENABLED && (
            <div className="flex flex-col justify-between items-center border-solid border-t border-b border-app-disabledlight py-2">
              <div className="flex justify-between w-full">
                <Radio
                  checked={radioOption.bonifico_pay}
                  containerClass="flex flex-row items-center"
                  labelClass="ml-2 font-normal not-italic font-tk-freight-sans-pro text-xl"
                  label={t(`${tCode}.iban`)}
                  onChange={() => updateRadioOption("bonifico_pay")}
                />
                <div>
                  <SepaIcon />
                </div>
              </div>
              {radioOption.bonifico_pay && <></>}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center mt-16">
          {
            <span
              className={`flex text-app-yellowDark text-md font-tk-usual ${
                stripe &&
                !pageLoading &&
                (isOptionSelected !== false || isOptionSelected !== null)
                  ? "opacity-0 transition-opacity translate-y-[50%]"
                  : "opacity-100 transition-transform translate-y-[0%]"
              }`}
            >
              {t(`${tCode}.loading`)}
            </span>
          }
          <Button
            disabled={!stripe || pageLoading || !isOptionSelected}
            variant={
              stripe && !pageLoading && isOptionSelected
                ? "primary"
                : "disabled-btn"
            }
            css={"scale-150"}
            type="submit"
          >
            {t(`${tCode}.pay`)}
          </Button>
        </div>

        <Modal
          title={t(`${pageTranslationCode}:modalErrorTitle`)}
          open={errorModalOpen}
          showCancel={false}
          closeModal={closeModal}
          //titleCancel={t(`${pageTranslationCode}.cancel`)}
          titleConfirm={t(`${pageTranslationCode}:OK`)}
          onSubmit={closeModal}
          /*confirmDisabled={
            (selectionType.assign_excel || selectionType.heading_excel) && state.bannerData?.status === "processing"
          }*/
        >
          <div className="flex flex-col space-y-5">
            {newErrorMessage}
          </div>
      </Modal>
      </form>
    </>
  );
};

export default PaymentMethods;
