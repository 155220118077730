/* #region PATH */
export const PATH_MAIN_MANAGMENT = "/main_management";
export const PATH_BUY = "/buy";
export const PATH_PROFILE = "/profile";
export const PATH_OPERATION_MANAGEMENT = "/op_management";
export const PATH_PURCHASE = "/purchase";
/* #endregion */
/* #region REGEX */
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PHONE_REGEX = /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const IS_NUMBER_REGEX = /^\d+$/;
/* #endregion */