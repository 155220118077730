import { Minus, Plus1 } from "../icons/d-ticketing/";
import { TextInput } from "../textinput/";

const Quantity = ( {item, onChangeQuantityHandler, error = false, pageTranslationCode} ) => {

  let disabledMinus = false;
  let disabledPlus = false;

  if (item.quantity < 1) {
    disabledMinus = true;
  }

  if ((item.limit !== null || item.limit !== undefined)
    && item.quantity >= item.limit)
  {
    disabledPlus = true;
  }

  const quantityButtonHandler = (value) => {
    const newQuantity = item.quantity + value
    onChangeQuantityHandler(newQuantity)
  };

  const textFieldHandler = (value) => {
    const newQuantity = Math.min(value, item.limit)
    onChangeQuantityHandler(newQuantity)
  };

  let minusButtonColor = "#000000"
  if(disabledMinus) {
    minusButtonColor = "#E0E0E0"
  }

  let plusButtonColor = "#000000"
  if(disabledPlus) {
    plusButtonColor = "#E0E0E0"
  }

  return (
    <div className="flex flex-row items-center ">
      <Minus
        width={32}
        height={32}
        fill={minusButtonColor}
        className={`${
          disabledMinus ? "cursor-not-allowed" : "cursor-pointer"
        } `}
        onClick={() => {
          if (!disabledMinus) quantityButtonHandler(-1);
        }}
      />
      <div className="rounded-sm box-border mx-3 w-14 h-12 flex items-center justify-center select-none">
        <TextInput
          value={item?.quantity?.toFixed(0)}
          id="easyticket-number"
          field="num"
          onChange={textFieldHandler}
          inputType="number"
          error={error}
          pageTranslationCode={pageTranslationCode}
        />
      </div>
      <Plus1
        width={32}
        height={32}
        fill={plusButtonColor}
        className={`${disabledPlus ? "cursor-not-allowed" : "cursor-pointer"} `}
        onClick={() => {
          if (!disabledPlus) quantityButtonHandler(1);
        }}
      />
    </div>
  );
};

export default Quantity;
